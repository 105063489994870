import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../App.css'; // Estilos personalizados para el carrusel
import imagen1 from '../img/imagencarusel1.jpg';
import imagen2 from '../img/carrusel2.jpg';
import imagen3 from '../img/carrusel3.jpg';
import imagen4 from '../img/carrusel4.jpg';
import imagen5 from '../img/carrusel5.jpg';
import imagen6 from '../img/carrusel6.jpg';
import imagen7 from '../img/carrusel7.jpg';
import imagen8 from '../img/carrusel8.jpg';
import imagen9 from '../img/carrusel9.jpg';

const CarouselComponent = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div className="carousel-wrapper" onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
      <Carousel
        showArrows={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={!isHovered} // Iniciar la reproducción automática solo si no está siendo hover
        interval={2000}
        infiniteLoop={true}
      >
        <div>
          <img src={imagen6} alt="Imagen 6" style={{ width: '60%', height: 'auto' }} />
        </div>
        <div>
          <img src={imagen7} alt="Imagen 7" style={{ width: '60%', height: 'auto' }} />
        </div>
        <div>
          <img src={imagen8} alt="Imagen 8" style={{ width: '60%', height: 'auto' }} />
        </div>
        <div>
          <img src={imagen1} alt="Imagen 1" style={{ width: '60%', height: 'auto' }} />
        </div>
        <div>
          <img src={imagen2} alt="Imagen 2" style={{ width: '60%', height: 'auto', }} />
        </div>
        <div>
          <img src={imagen3} alt="Imagen 3" style={{ width: '60%', height: 'auto' }} />
        </div>
        <div>
          <img src={imagen4} alt="Imagen 4" style={{ width: '60%', height: 'auto  ' }} />
        </div>
        <div>
          <img src={imagen5} alt="Imagen 5" style={{ width: '60%', height: 'auto' }} />
        </div>
        <div>
          <img src={imagen9} alt="Imagen 5" style={{ width: '60%', height: 'auto' }} />
        </div>
      </Carousel>
    </div>
  );
};

export default CarouselComponent;
