import React from 'react';
import '../App.css';


function Inicio() {
  return (
    <section id="inicio">
        <div className="hero">
          <h1 className='texto-portada'>Escuela Nueva Zelandia</h1>
        </div>
      </section>
  );
}

export default Inicio;
